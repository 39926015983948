<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <span v-if="showType == 'hund'">
          <h2>Vergleich starten</h2>
          <p>Vergleichen Sie jetzt die besten Hunde-Krankenversicherungen.</p>
          <v-btn
            href="/rechner"
            class="nocaps mb-4"
            style="background-color: #d84315; color: #fff"
            >jetzt Vergleich starten</v-btn
          >
        </span>
        <span v-if="showType == 'katze'">
          <h2>Vergleich starten</h2>
          <p>Vergleichen Sie jetzt die besten Katzen-Krankenversicherungen.</p>
          <v-btn
            href="/vergleich_katze"
            class="nocaps mb-4"
            style="background-color: #fecf0c; color: #fff"
            >jetzt Vergleich starten</v-btn
          >
        </span>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: "rechner-start-box",
    components: {},
    props: ["type"],
    data: function() {
      return {
        showType: "hund",
      };
    },
    created() {
      if (this.type == "katze") {
        this.showType = "katze";
      }
    },

    methods: {},
  };
</script>
