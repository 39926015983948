<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <a :style="cssstyle" v-bind="attrs" v-on="on" @click="raiseClickEvent()">
        {{ text }}
      </a>
    </template>
    <span> {{ tooltipValue }} </span>
  </v-tooltip>
</template>

<script>
import fieldMixin from "./../../mixins/fieldMixin";
export default {
  mixins: [fieldMixin],
  name: "ButtonTip",
  props: {
    text: { type: String, required: false, default: "" },
    icon: { type: String, required: false, default: "" },
    color: { type: String, required: false, default: "primary" },
    cssstyle: { type: String, required: false, default: "" },
    iconcolor: { type: String, required: false, default: "white" },
    tooltip: { type: String, required: false, default: "" },
    eventname: { type: String, required: false, default: "clickyclicky" },
  },
  data: function () {
    return {
      tooltipValue: "",
    };
  },
  created() {
    if (this.tooltip) {
      this.tooltipValue = this.showFieldComputedPop(this.tooltip);
    }
  },

  methods: {
    raiseClickEvent() {
      this.$emit(this.eventname);
    },
  },
};
</script>